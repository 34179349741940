<template>
    <v-container>
        <validation-observer tag="div" ref="observer">
            <v-card class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Hotel</div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <zw-autocomplete v-model="form.hotel"
                                             name="hotel"
                                             label="Hotel"
                                             :items="getHotels()"
                                             item-text="name"
                                             item-value="sku"
                                             validation="required"
                                             @change="dateChanged"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-date-picker v-model="form.from"
                                            name="from"
                                            label="From"
                                            :validation="{
                                            required: true,
                                            regex: /(^\d{2}.\d{2}.\d{4}$)/,
                                            min_date: (new Date(Date.now())).toISOString().substr(0, 10)
                                            }"
                                            :min="(new Date(Date.now())).toISOString().substr(0, 10)"
                                            :max="form.to"
                                            @change="(val)=>{minDate = val}"
                                            @input="()=>{dateChanged()}"
                            ></zw-date-picker>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-date-picker v-model="form.to"
                                            name="to"
                                            label="To"
                                            :validation="{
                                            required: true,
                                            regex: /(^\d{2}.\d{2}.\d{4}$)/,
                                            min_date: form.from
                                            }"
                                            :min="form.from"
                                            :picker-date.sync="minDate"
                                            @input="()=>{dateChanged()}"
                            ></zw-date-picker>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field v-model="form.persons"
                                           name="persons"
                                           label="Persons"
                                           type="number"
                                           validation="required"
                                           @change="personsCountChanged"
                            ></zw-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mb-3" v-if="form.travelers.length">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Reiseanmelder</div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <zw-autocomplete v-model="form.travelers[0].prefix"
                                             name="prefix"
                                             label="Anrede"
                                             :items="getPrefixes() | optionsVL"
                                             validation="required"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-autocomplete v-model="form.travelers[0].title"
                                             name="title"
                                             label="Titel"
                                             :items="getTitles() | optionsVL"
                                             validation="required"
                            ></zw-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field v-model="form.travelers[0].firstname"
                                           name="firstname"
                                           label="Vorname"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field v-model="form.travelers[0].lastname"
                                           name="lastname"
                                           label="Nachname"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="PLZ"
                                           name="zip"
                                           v-model="form.customer.zip"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="Ort"
                                           v-model="form.customer.city"
                                           name="city"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="Straße, Nr."
                                           v-model="form.customer.address"
                                           name="address"
                                           validation="required"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="Adressezusatz"
                                           name="additional_address"
                                           v-model="form.customer.additional_address"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="Telefon"
                                           name="phone"
                                           v-model="form.customer.phone"
                            ></zw-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <zw-text-field label="E-mail"
                                           naame="email"
                                           v-model="form.customer.email"
                            ></zw-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Reisende</div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <zw-checkbox v-model="form.customer.as_traveler"
                                         name="as_traveler"
                                         label="Meine Daten übernehmen"
                                         @change="asTravelerChanged"
                            ></zw-checkbox>
                        </v-col>
                    </v-row>

                    <template v-for="(traveler,index) in form.travelers">
                        <v-row :key="index"
                               v-if="index> 0 || (index==0 && form.customer.as_traveler)"
                               :class="getTravelerNr(index) % 2 ? 'grey lighten-3' : ''"
                        >
                            <v-col style="max-width: 35px">
                                <div class="title">{{ getTravelerNr(index) }}</div>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-col cols="12" sm="6" md="3">
                                        <zw-autocomplete v-model="traveler.prefix"
                                                         :name="index + '_prefix'"
                                                         label="Anrede"
                                                         :items="getPrefixes() | optionsVL"
                                                         validation="required"
                                        ></zw-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <zw-autocomplete v-model="traveler.title"
                                                         :name="index + '_title'"
                                                         label="Titel"
                                                         :items="getTitles() | optionsVL"
                                                         validation="required"
                                        ></zw-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <zw-text-field v-model="traveler.firstname"
                                                       :name="index + '_firstname'"
                                                       label="Vorname"
                                                       validation="required"
                                        ></zw-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <zw-text-field v-model="traveler.lastname"
                                                       :name="index + '_lastname'"
                                                       label="Nachname"
                                                       validation="required"
                                        ></zw-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="3">
                                        <zw-date-picker v-model="traveler.birthday"
                                                        :name="index + '_birthday'"
                                                        active-picker="YEAR"
                                                        label="Geburtsdatum"
                                                        :validation="{required: true, regex: /(^\d{2}.\d{2}.\d{4}$)/ }"
                                                        :max="(new Date(Date.now())).toISOString().substr(0, 10)"
                                                        min="1900-01-01"
                                        ></zw-date-picker>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <zw-autocomplete v-model="traveler.sex"
                                                         :name="index + '_sex'"
                                                         label="Geschlecht"
                                                         validation="required"
                                                         :items="getGenders() | optionsVL"
                                        ></zw-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <zw-autocomplete v-model="traveler.allergic"
                                                         :name="index + '_allergic'"
                                                         label="Allergiker"
                                                         :items="yesNoOptions"
                                        ></zw-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3" v-if="traveler.allergic">
                                        <zw-text-field v-model="traveler.additional"
                                                       :name="index + '_additional'"
                                                       label="bekannte Allergien"
                                        ></zw-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="3">
                                        <zw-autocomplete v-model="traveler.diabetic"
                                                         :name="index + '_diabetic'"
                                                         label="Diabetiker"
                                                         :items="yesNoOptions"
                                        ></zw-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <zw-autocomplete v-model="traveler.status"
                                                         :name="index + '_status'"
                                                         label="Impfstatus"
                                                         :items="getStatuses() | optionsVL"
                                        ></zw-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <zw-checkbox v-model="traveler.handicap_accessible_room"
                                                     :name="index + '_handicap_accessible_room'"
                                                     label="Behindertengerechteszimmer"
                                        ></zw-checkbox>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <zw-checkbox v-model="traveler.pets"
                                                     :name="index + '_pets'"
                                                     label="Haustiere"
                                        ></zw-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </template>
                </v-card-text>
            </v-card>

            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-btn>Zurück</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="text-right">
                            <v-btn color="primary"
                                   @click="nextStep()"
                                   :disabled="error"
                            >next step
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </validation-observer>
    </v-container>
</template>

<script>
import ZwAutocomplete from "../components/ZwAutocomplete";
import ZwDatePicker from "../components/ZwDatePicker";
import ZwTextField from "../components/ZwTextField";
import ZwCheckbox from "../components/ZwCheckbox";
import {mapGetters} from 'vuex'
import moment from 'moment'

export default {
    name: 'Travelers',
    components: {ZwCheckbox, ZwTextField, ZwDatePicker, ZwAutocomplete},
    data: () => ({
        form: {
            hotel: null,
            hotel_data: null,
            from: null,
            to: null,
            persons: null,
            customer: {
                as_traveler: true,
            },
            travelers: [],
            offering_id: null,
            same_transfer: false,
            same_address: false,
            same_insurance: false,
            transfers: [],
            insurances: [],
            transfer: {
                same: false,
            },
            rooms: [],
            services: [],
            address: {
                prefix: null,
                title: null,
                firstname: null,
                lastname: null,
                zip: null,
                city: null,
                address: null,
                additional_address: null,
            },
            register: false,
            email: null,
            password: null,
            password2: null,
            request: false,
        },
        yesNoOptions: [
            {text: 'Nein', value: false},
            {text: 'Ja', value: true},
        ],
        minDate: null,
        error: false,
    }),
    mounted() {
        this.$root.$children[0].showLoading()

        const hotelsList = this.$store.dispatch('Hotels/fetchHotels')
        const travelersOptions = this.$store.dispatch('Options/fetchOptions')

        Promise.all([hotelsList, travelersOptions])
            .finally(() => {
                let hash = this.$route.query.hash || null
                if (hash) {
                    this.$store.dispatch('Travelbox/fetchData', hash)
                        .then(() => {
                            this.form = this.getData()
                        })
                        .finally(() => {
                            this.$root.$children[0].hideLoading()

                        })
                } else {
                    let hasFormData = localStorage.getItem('form') || null

                    if (hasFormData) {
                        this.form = JSON.parse(localStorage.getItem('form')) || {}
                        this.$root.$children[0].hideLoading()
                    } else {
                        this.form.hotel = this.$route.query.sku || null
                        this.form.from = this.$route.query.from_date || null
                        this.form.to = this.$route.query.till_date || null
                        this.form.persons = this.$route.query.persons || 1

                        localStorage.setItem('id', null)

                        this.$root.$children[0].hideLoading()

                        for (let i = 0; i < this.form.persons; i++) {
                            this.addNewTraveler()
                        }
                    }
                }
            })
    },
    methods: {
        ...mapGetters('Travelbox', ['getData']),
        ...mapGetters('Hotels', ['getHotels']),
        ...mapGetters('Options', ['getGenders', 'getPrefixes', 'getTitles', 'getStatuses']),
        getTravelerNr(index) {
            return this.form.customer.as_traveler ? index + 1 : index
        },
        addNewTraveler() {
            this.form.travelers.push({
                prefix: 'herr',
                title: 'ohne',
                allergic: false,
                diabetic: false,
                birthday: null,
            })
        },
        dateChanged() {
            this.validateMinDays()
        },
        validateMinDays() {
            this.error = true
            if (this.form.to && this.form.from) {
                this.$store.dispatch('Hotels/checkMinDays', {
                    date: this.form.to,
                    sku: this.form.hotel,
                }).then((response) => {
                    let duration = moment.duration(moment(this.form.to).diff(moment(this.form.from))).asDays()

                    if (duration < response.data.min_days) {
                        this.$refs['observer'].setErrors({
                            'to': 'Min days'
                        })

                        this.error = true
                        this.$root.$children[0].showMessage('Minimum nights for this hotel: ' + response.data.min_days, 'warning')
                    } else {
                        this.error = false
                    }
                })
            }

        },
        nextStep() {
            this.validateMinDays()
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$root.$children[0].showLoading()
                    this.form.hotel_data = this.getHotels().find(hotel => hotel.sku == this.form.hotel) || null
                    localStorage.setItem('form', JSON.stringify(this.form))
                    this.$router.push('/room')
                    // this.$store.dispatch('Travelbox/savePersons', this.form)
                    //     .then((response) => {
                    //         localStorage.setItem('hash', response.data.hash)
                    //         this.$router.push('/room')
                    //     })
                    //     .catch((error) => {
                    //         this.$root.$children[0].hideLoading()
                    //         this.$root.$children[0].showMessage(error.message || 'Server error', 'error')
                    //     })
                }
            })
        },
        personsCountChanged(val) {
            let neededCount = this.form.customer.as_traveler ? parseInt(val) : parseInt(val) + 1
            if (this.form.travelers.length > neededCount) {
                this.form.travelers = this.form.travelers.slice(0, neededCount);
            } else {
                while (this.form.travelers.length < neededCount) {
                    this.addNewTraveler()
                }
            }
        },
        asTravelerChanged(val) {
            if (val) {
                this.form.travelers.pop();
            } else {
                this.form.travelers.push({})
            }
        }
    },
}
</script>